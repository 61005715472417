<template>
  <b-sidebar
    id="add-new-invoice-sidebar"
    :visible="isAddNewInvoiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-invoice-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.invoices.createNewInvoice") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewinvoice())"
          @reset.prevent="resetForm"
        >
          <!-- Number -->
          <validation-provider
            #default="validationContext"
            name="número"
            rules="required"
          >
            <b-form-group
              label-for="number"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.invoices.number") }}
              </template>
              <b-form-input
                id="number"
                v-model="invoiceData.number"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Contract Id -->
          <validation-provider
            #default="validationContext"
            name="contrato"
            rules="required"
          >
            <b-form-group
              label-for="contract"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.contracts.contracts") }}
              </template>
              <v-select
                v-model="invoiceData.contractId"
                @input="installmentValues(invoiceData.contractId)"
                :options="contractOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Customer Id -->
          <validation-provider
            #default="validationContext"
            name="cliente"
            rules="required"
          >
            <b-form-group
              label-for="customer"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.customer") }}
              </template>
              <v-select
                v-model="invoiceData.customerId"
                :options="customerOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Contract Installment -->
          <validation-provider
            #default="validationContext"
            name="parcela"
            rules="required"
          >
            <b-form-group
              label-for="installment"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.contracts.parcel") }}
              </template>
              <b-form-input v-if="installmentOptions.length === 0" readonly />
              <v-select
                v-else
                multiple
                v-model="invoiceData.installment"
                @input="installmentValue()"
                :options="installmentOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Value -->

          <validation-provider
            #default="validationContext"
            name="valor"
            rules="required"
          >
            <b-form-group
              label-for="value"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.expenses.value") }}
              </template>
              <b-form-input
                id="value"
                v-money="getCurrencyFormat()"
                v-model="invoiceData.value"
                format="currency"
              />
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            #default="validationContext"
            name="data"
            rules="required"
          >
            <b-form-group label-for="date">
              <template v-slot:label>
                {{ $t("message.tableHeader.date") }}
              </template>
              <flat-pickr
                v-model="invoiceData.invoiceDate"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ $t("message.list.fieldRequired") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Expiry Date -->
          <validation-provider
            #default="validationContext"
            name="vencimento"
            rules="required"
          >
            <b-form-group label-for="expiryDate">
              <template v-slot:label>
                {{ $t("message.bills.expiryDate") }}
              </template>
              <flat-pickr
                v-model="invoiceData.invoiceExpiryDate"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ $t("message.list.fieldRequired") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="estado"
            rules="required"
          >
            <b-form-group
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.status") }}
              </template>
              <v-select
                v-model="invoiceData.status"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract-role"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group label-for="status">
            <template v-slot:label>
              {{ $t("tax") }}
            </template>
          </b-form-group>
          <div class="form-group" v-for="(input, k) in taxesObj" :key="k">
            <b-row>
              <b-col md="4">
                <b-form-input
                  :placeholder="$t('taxName')"
                  v-model="input.name"
                />
              </b-col>
              <b-col md="4">
                <b-form-input
                  :placeholder="$t('taxValue')"
                  v-model="input.value"
                  v-money="getCurrencyFormat()"
                  format="currency"
                />
              </b-col>

              <b-col md="2">
                <b-button
                  v-show="k == taxesObj.length - 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="addInstallment(k)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  md="2"
                  v-show="k || (!k && taxesObj.length > 1)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                  @click="removeInstallment(k)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>
          </div>

          <br />
          <hr class="mt-2 mb-3" />

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BRow,
  BCol,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    BRow,
    BCol,
    BInputGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
    money: VMoney,
  },

  model: {
    prop: "isAddNewInvoiceSidebarActive",
    event: "update:is-add-new-invoice-sidebar-active",
  },
  props: {
    isAddNewInvoiceSidebarActive: {
      type: Boolean,
      required: true,
    },
    customerOptions: {
      type: Array,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      required,
      alphaNum,

      flatConfig: {
        dateFormat: "d/m/Y",
      },

      installmentOptions: [],
      installmentList: [],
      totalValue: "",

      contractList: [],

      //Paramf
      paramData: store.state.user.paramData,

      userData: store.state.user.userData,

      disabledButton: false,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      taxesObj: [
        {
          name: "",
          value: "",
        },
      ],
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  async created() {
    await axios
      .get(
        `${URL_API}contract/list?page=0&sort=id,${this.paramData.listSort}&size=999`,
        {
          headers: getHeader(this.userData),
        }
      )
      .then((response) => {
        this.contractList = response.data.content;
      });
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    submitNewinvoice() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          this.invoiceData.invoiceDate = date(
            this.$i18n.locale,
            this.invoiceData.invoiceDate
          );
          this.invoiceData.invoiceExpiryDate = date(
            this.$i18n.locale,
            this.invoiceData.invoiceExpiryDate
          );

          if (this.$i18n.locale === "en") {
            this.invoiceData.value = Number(
              this.invoiceData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          } else {
            this.invoiceData.value = this.switchDotsAndCommas(
              this.invoiceData.value
            );
            this.invoiceData.value = Number(
              this.invoiceData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          }

          var obj = [];
          if (this.taxesObj[0].name != "" && this.taxesObj[0].value != "") {
            for (var i = 0; i < this.taxesObj.length; i++) {
              if (this.$i18n.locale === "en") {
                obj.push({
                  name: this.taxesObj[i].name,
                  value: Number(
                    this.taxesObj[i].value.replace(/[^0-9\.-]+/g, "")
                  ).toFixed(2),
                });
              } else {
                var a = this.switchDotsAndCommas(this.taxesObj[i].value);
                obj.push({
                  name: this.taxesObj[i].name,
                  value: Number(a.replace(/[^0-9\.-]+/g, "")).toFixed(2),
                });
              }
            }
          }

          if (obj.length > 0) {
            this.invoiceData.taxes = obj;
          }

          axios({
            method: "post",
            url: `${URL_API}invoice`,
            headers: getHeader(this.userData),
            data: this.invoiceData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Nota fical criada com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListInvoice", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    addInstallment() {
      this.taxesObj.push({ name: "", value: "" });
    },

    removeInstallment(index) {
      this.taxesObj.splice(index, 1);
    },

    async installmentValues(id) {
      this.installmentOptions = [];
      this.invoiceData.installment = [];
      this.invoiceData.value = null;

      this.totalValue = "";
      this.installmentList = [];

      console.log("this.contractList", this.contractList)
      for (let i = 0; i < this.contractList.length; i++) {
        if (this.contractList[i].id === id) {
          this.totalValue = this.contractList[i].value;
          this.installmentList = this.contractList[i].installmentList;
          this.invoiceData.customerId = this.contractList[i].customerId;
        }
      }

      for (var i = 0; i < this.installmentList.length; i++) {
        this.installmentOptions.push({ label: i + 1, value: i + 1 });
      }
    },

    installmentValue() {
      var percentage = [];
      for (var i = 0; i < this.invoiceData.installment.length; i++) {
        for (var j = 0; j < this.installmentList.length; j++) {
          if (this.invoiceData.installment[i] - 1 === j) {
            percentage.push(this.installmentList[j]);
          }
        }
      }

      var numbers = percentage.map(Number);
      var sum = numbers.reduce((a, b) => a + b, 0);
      var finalValue = ((sum / 100) * parseFloat(this.totalValue)).toFixed(2);

      this.invoiceData.value = finalValue;
    },
  },

  setup(props, { emit }) {
    const blankinvoiceData = {};

    const invoiceData = ref(JSON.parse(JSON.stringify(blankinvoiceData)));
    const resetinvoiceData = () => {
      invoiceData.value = JSON.parse(JSON.stringify(blankinvoiceData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-invoice-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetinvoiceData);

    return {
      invoiceData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-invoice-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
